<template>
    <div class="main-limiter margin-bottom-5">
        <article class="main">
            <h1>Choosing the right course for a Practical NLP Engineer</h1>
            <DoroImage :src="ImageMain" max-width="300px"></DoroImage>

            <p>Selecting an online course that will match your requirements is very frustrating if you have high standards. Most of them are not comprehensive, and a lot of time spent on them is wasted.</p>
            <p>How would you feel if someone would provide you a critical path and tell, what modules exactly and in which order will offer you comprehensive, expert-level knowledge? Awesome.</p>
            <p>That is why I am going to help you with this guide to selecting a Natural Language Processing course, utilizing my eight years of practical experience in Machine Learning. I've personally completed or skimmed over the 15 most popular courses.</p>
            <p>In my rigorous review, I focus on the practical and business knowledge that they provide.</p>
            <p>This article was thought to provide great value whether you are just starting your journey with NLP or thinking how to implement modern algorithms into your business model. Take a look at the Critical Path and pick modules that you are interested in the most.</p>

            <h2>My dream NLP course</h2>
            <p>What I have in mind when I think about the perfect one:</p>
            <ul><li>It is organized around major practical problems.</li><li>It covers only the practically useful information with optional references to more in-depth, supplemental parts of the theory.</li><li>Content is being frequently updated, at least every time when there is a major change in the state of the art.</li><li>Content is certified by a group of experts with a lot of practical experience in the field.</li><li>It contains real-life code samples that solve real business problems.</li></ul>

            <h2>Reality is more complicated...</h2>
            <DoroImage :src="ImageNLPCoursesReview" max-width="683px"></DoroImage>

            <h2>Code-first courses</h2>

            <h3>1. <URL to="https://github.com/fastai/course-nlp">A Code-First Introduction to NLP course</URL></h3>
            <DoroImage :src="ImageTable1"></DoroImage>
            <p><b>Author:</b> Fast AI</p>
            <p><b>Expert's opinion:</b> 8/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>I personally enjoy the top-down way of learning. The course is solid-written (no wonder, the editor was Jeremy Howard himself). It is very practical and updated by the latest algorithms (state of at least the summer of 2019). It lacks the practical business approach and is concentrated only on algorithms on their actual usage. Highly recommended lecture!</p>
            <p><b>Tools &amp; Libraries</b>: IPython notebooks, PyTorch, Fast.AI, Spacy, Sklearn, Nltk<br></p>

            <h3>2. <URL to="https://github.com/yuibi/spacy_tutorial">SpaCy Tutorial</URL></h3>
            <DoroImage :src="ImageTable2"></DoroImage>
            <p><b>Author:</b> Yuibi Fujimoto</p>
            <p><b>Expert's opinion:</b> 7/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>I personally enjoy the top-down way of learning. The course is solid-written (no wonder, the editor was Jeremy Howard himself). It is very practical and updated by the latest algorithms (state of at least the summer of 2019). It lacks the practical business approach and is concentrated only on algorithms on their actual usage. Highly recommended lecture!</p>
            <p><b>Tools &amp; Libraries</b>: Spacy, Prodigy, Sklearn<br></p>

            <h3>3. <URL to="https://github.com/yandexdataschool/nlp_course/tree/master">Natural Language Processing</URL></h3>
            <DoroImage :src="ImageTable3"></DoroImage>
            <p><b>Author:</b> Yandex</p>
            <p><b>Expert's opinion:</b> 7/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This is a very fresh and practical course covering the most important areas. Sadly it didn't focus on the practical business tasks, but it is only focused on algorithms and their direct applications.</p>
            <p><b>Tools &amp; Libraries</b>: Keras, NTLK, Gensim, Sklearn, Bokeh, Matplotlib<br></p>

            <h3>4. <URL to="https://www.coursera.org/learn/language-processing">Natural Language Processing</URL></h3>
            <DoroImage :src="ImageTable4"></DoroImage>
            <p><b>Author:</b> HSE Faculty of Computer Science / Yandex</p>
            <p><b>Expert's opinion:</b> 3/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This is a short, basic course that is mostly focused on a raw theory. It isn't comprehensive, lacks recent algorithms and practical business cases. It uses raw Tensorflow without Keras, that is not a recommended way to learn deep learning.</p>
            <p><b>Tools &amp; Libraries</b>: NLTK, Sklearn, Tensorflow, Gensim, StarSpace<br></p>

            <h3>5. <URL to="https://course.spacy.io/en/">Advanced NLP with spaCy</URL></h3>
            <DoroImage :src="ImageTable5"></DoroImage>
            <p><b>Author:</b> Explosion AI</p>
            <p><b>Expert's opinion:</b> 8/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course is focused on Spacy alone. However, it covers a broad range of topics. The clean and nice course interface made me smile, it is very esthetic. Short course, but highly recommended! It contains real-time graded practical exercises!</p>
            <p><b>Tools &amp; Libraries</b>: Spacy<br></p>

            <h3>6. <URL to="https://www.coursera.org/learn/natural-language-processing-tensorflow">Natural Language Processing Certification in TensorFlow</URL> (Coursera)</h3>
            <DoroImage :src="ImageTable6"></DoroImage>
            <p><b>Author:</b> Laurence Moroney</p>
            <p><b>Expert's opinion:</b> 2/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This short course explains basic knowledge, such as tokenizing, few embedding techniques, and sequence models. It is focused on theory with short quizzes. We wouldn't recommend it even to beginners, because it lacks in almost every aspect.</p>
            <p><b>Tools &amp; Libraries</b>: TensorFlow<br></p>

            <h3>7. <URL to="https://www.edx.org/course/natural-language-processing-nlp-2">Natural Language Processing Course</URL> (edX)</h3>
            <DoroImage :src="ImageTable7"></DoroImage>
            <p><b>Author:</b> Microsoft</p>
            <p><b>Expert's opinion:</b> 7/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This short course introduces methods to work on NLP and machine learning techniques that can be used for that. You can learn about classical and deep learning algorithms as well as Deep Semantic Similarity Model and how to use them in real-life situations. Every module has knowledge checks and practical assignments, which are helpful in a deeper understanding of the topic.</p>

            <h3>8. <URL to="https://www.codecademy.com/learn/natural-language-processing">Natural Language Processing</URL> (Codecademy)</h3>
            <DoroImage :src="ImageTable8"></DoroImage>
            <p><b>Expert's opinion:</b> 5/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This short course (~10 hours) is an introduction to NLP. It contains basic descriptions of regular expressions, text processing, word embedding, and term frequency-inverse document frequency. In my honest opinion, it suits the best people with no prior NLP experience who want to get a grasp of it.</p>
            <p><b>Tools &amp; Libraries</b>: NLTK, re<br></p>

            <h3>9. <URL to="https://www.datacamp.com/courses/natural-language-processing-fundamentals-in-python">Natural Language Processing Fundamentals in Python</URL> (Datacamp)</h3>
            <p><b>Author:</b> Katharine Jarmul</p>
            <p><b>Expert's opinion:</b> 7/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course is based on the idea of teaching by solving short problems. It introduces some embeddings and preprocessing techniques as well as TF-IDF and introduction to SpaCy. We think that it is a very good course for beginners that ends with a bigger practical exercise.</p>
            <p>We've decided to exclude it from the comparison. </p>
            <p><b>Tools &amp; Libraries</b>: NLTK, re, Gensim, polyglot, spaCy, scikit-learn<br></p>

            <h2>University Courses</h2>

            <h3>10. <URL to="http://people.ischool.berkeley.edu/~dbamman/info256.html">Applied Natural Language Processing</URL> (Berkley)</h3>
            <p><b>Author:</b> David Bamman</p>
            <p><b>Expert's opinion:</b> 8/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course is for people with prior python programming experience, but new to NLP. You will use popular libraries such as scikit-learn, keras, spacy to implement solutions for classification and regression problems. We think that obligatory semester-long projects and the introduction of modern neural methods will be beneficial for the student.</p>

            <h3>11. <URL to="https://courses.cs.washington.edu/courses/cse517/17wi/">Natural Language Processing</URL> (Washington)</h3>
            <p><b>Author:</b> Yejin Choi</p>
            <p><b>Expert's opinion:</b> 6/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course is aimed at NLP beginners that would like to get a theoretical understanding of basic techniques. It was created in 2017, so you won't find there any modern solutions. This course presents topics such as sequences, semantics, and deep learning, but we think that students should make his own research after finishing it to deepen his knowledge.</p>

            <h3>12. <URL to="https://www.cs.ox.ac.uk/teaching/courses/2016-2017/dl/">Deep Learning for Natural Language Processing</URL> (Oxford)</h3>
            <p><b>Author:</b> Phil Blunsom</p>
            <p><b>Expert's opinion:</b> 8/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course requires prior machine learning experience and broad mathematical knowledge, including probability, linear algebra, and continuous mathematics.</p>
            <p>You will be able to acquire a broad set of skills starting from Recurrent Neural Networks, Backpropagation Through Time and ending on heavily practical skills such as GPU optimization for Neural Networks. We would recommend students finishing this course to make further research on newly developed methods because this one course is not being updated.</p>

            <h3>13. <URL to="https://www.cs.tau.ac.il/~joberant/teaching/nlp_spring_2019/index.html">Advanced Methods in Natural Language Processing</URL> (Tel Aviv University)</h3>
            <p><b>Author:</b> Jonathan Berant</p>
            <p><b>Expert's opinion:</b> 7/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>The course offered by Tel Aviv University is focused on teaching modern methods in the field of NLP. It requires prior machine learning knowledge, but no NLP experience. They have prepared a repository of reports really worth reading.</p>

            <h3>14. <URL to="https://web.stanford.edu/class/cs124/">From Languages to Information</URL> (Stanford)</h3>
            <p><b>Author:</b> Dan Jurafsky</p>
            <p><b>Expert's opinion:</b> 6/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>This course is focused on NLP but introduces students also to other machine learning topics. One of them is social networks that aren't covered well in other courses, so it is a very unique point of this one. Students of this course will gain broad knowledge, but I won't recommend it to people interested strictly in NLP.</p>

            <h3>15. <URL to="https://web.stanford.edu/class/cs224n/">Natural Language Processing with Deep Learning</URL> (Stanford)</h3>
            <p><b>Author:</b> Chris Manning, Matthew Lamm</p>
            <p><b>Expert's opinion:</b> 8/10</p>
            <p><b>Subjective opinion &amp; comments:</b><br>Project-oriented up to date course that includes recent advances in the field. Prior mathematical and programming (Python or strong C++) is required. I recommend tit course only to experienced people that want to gain more in-depth knowledge.</p>

            <h2>Summary</h2>
            <p>After analyzing all the courses, I have come to the conclusion that none of them is fully comprehensive when it comes to modern algorithmic methods, and business use cases of NLP are even more obsolete. Most of them lack true practical exercises, which based on<URL to="https://www.cbsnews.com/news/whats-the-best-way-to-learn-psychologists-tackle-studying-techniques/"> psychological studies conducted at Kent State University</URL>, bly hampers knowledge ingestion.</p>
            <p>Let's recap the main challenges that practical engineers face during a typical NLP course:</p>
            <ul>
                <li>The course becomes quickly obsolete. I expect the course to contain the most recent (not necessarily complex) knowledge.</li>
                <li>There are none or not enough practical exercises to reinforce theoretical knowledge gained during the course.</li>
                <li>No one would help you to go through the exercises if you got stuck.</li>
                <li>The course is algorithm-focused instead of teaching how to solve real problems.</li>
            </ul>

            <h2>The End</h2>
            <p>There are many sub-standard courses that are not even being updated anymore. People fall in the trap and pay for them, but get no real practical value from them. <br></p>
        </article>
    </div>
</template>

<script>
    import { Component, Vue } from 'vue-property-decorator';
    import DoroImage from '@/components/DoroImage';
    import URL from '@/components/URL';

	import ImageMain from '@/assets/articles/main-photo/ultimate-guide-to-natural-language-processing-courses.png';

	import ImageNLPCoursesReview from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/nlp-courses-review.jpg';
	import ImageTable1 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-1.jpg';
	import ImageTable2 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-2.jpg';
	import ImageTable3 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-3.jpg';
	import ImageTable4 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-4.jpg';
	import ImageTable5 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-5.jpg';
	import ImageTable6 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-6.jpg';
	import ImageTable7 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-7.jpg';
	import ImageTable8 from '@/assets/articles/ultimate-guide-to-natural-language-processing-courses/table-8.jpg';

	@Component({
        components: {
            DoroImage,
            URL,
        },
        metaInfo() {
            return {
                title: 'Ultimate Guide to Natural Language Processing Courses',
                // link: [
                //     { rel: "amphtml", href: "https://airev.us/amp/ultimate-guide-to-natural-language-processing-courses" },
                // ],
                meta: [
                ],
            }
        }
    })
    export default class UltimateGuideToNaturalLanguageProcessingCourses extends Vue {
		get ImageMain() { return ImageMain; }

		get ImageNLPCoursesReview() { return ImageNLPCoursesReview; }
		get ImageTable1() { return ImageTable1; }
		get ImageTable2() { return ImageTable2; }
		get ImageTable3() { return ImageTable3; }
		get ImageTable4() { return ImageTable4; }
		get ImageTable5() { return ImageTable5; }
		get ImageTable6() { return ImageTable6; }
		get ImageTable7() { return ImageTable7; }
		get ImageTable8() { return ImageTable8; }
    }
</script>
